import "./App.css";
import logo from "./assets/images/banner_cdida.png"

function App() {
  return (
    <div className="">
      {/* Navbar */}
      <nav>
        <a href="app.js">
          <img src={logo} alt="logo"></img>
        </a>
        <ul>
          <a href="app.js">
            <li>Notre Association</li>
          </a>
          <a href="app.js">
            <li>Nos Ateliers</li>
          </a>
          <a href="app.js">
            <li>Contact</li>
          </a>
        </ul>
      </nav>

      {/* Section : Landing */}
      <div>
        <h1>Champigny d'Ici et d'Ailleurs*</h1>
        <h3>Apprendre le français à Champigny</h3>
        <p>
          * Association loi 1901 d'intérêt général, créée en 2022 et basée à
          Champigny-sur-Marne, dans le Val de Marne. Son objectif est la
          promotion et la diffusion de la langue et de la culture françaises à
          des allophones, dans un but d'éducation, de formation, d'insertion,
          d'autonomie sociale, et ce, par divers moyens pédagogiques.
        </p>
      </div>

      {/* Section : Notre Association */}
      <div>
        <div>
          <h2>Notre Association</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            varius enim in eros elementum tristique.
          </p>
        </div>

        <div>
          <div>
            <h3>C'est qui ?</h3>
            <p>
              Une équipe de bénévoles investies qui vous donnent des cours de
              français et des cours d'alphabétisation. Des personnes animées par
              la volonté de vous aider. Des enseignants de français qui
              travaillent ou ont travaillé dans le primaire ou le secondaire.
              Des formateurs professionnels bénévoles formés spécifiquement à
              l'enseignement du français aux étrangers (FLE) et aux adultes peu
              ou pas scolarisés.
            </p>
          </div>

          <div>
            <img src={require("./assets/images/olivier-1.png")} alt=""></img>
          </div>
        </div>

        <div>
          <div>
            <h3>Pour qui ?</h3>
            <p>
              Si vous êtes allophone (votre langue maternelle n'est pas le
              français). Si vous êtes originaire ou non de l'Union Européenne.
              Si vous séjournez en France depuis moins de 5 ans et souhaitez
              vivre durablement dans le pays (primo-arrivants). Si vous avez été
              scolarisé ou non dans votre pays d'origine. Si vous avez besoin
              d'améliorer votre parcours d'insertion grâce à l'apprentissage
              linguistique, ou si vous souhaitez compléter votre engagement avec
              le CIR (Contrat d'Intégration Républicaine).
            </p>
          </div>

          <div>
            <img src={require("./assets/images/olivier-2.png")} alt=""></img>
          </div>
        </div>

        <div>
          <div>
            <h3>Comment ?</h3>
            <p>
              Vous participez à des ateliers de français organisés par niveaux.
              Les programmes des cours sont conçus en fonction de vos acquis et
              de vos besoins, pour votre vie personnelle, sociale et
              professionnelle.
            </p>
          </div>

          <div>
            <img src={require("./assets/images/olivier-3.png")} alt=""></img>
          </div>
        </div>
      </div>

      {/* Section : Nos Ateliers */}

      <h2>Nos Ateliers</h2>
      <p>Vous participez à des ateliers de français organisés par niveaux*
      * Peu ou pas scolarisés (Niveaux Alpha / A1.1)
      Vous allez découvrir petit à petit :
      ⦁	Comment on dit les choses
      ⦁	Comment on écrit
      ⦁	Comment on lit



      ⦁	Scolarisés dans votre pays d’origine (Niveaux A1/A2/B1)
      A partir des bases, vous améliorez vos compétences orales et écrites en français, grâce au vocabulaire et aux règles de la langue française (grammaire et conjugaison) pour mieux comprendre, communiquer et agir dans votre vie quotidienne (par exemple pour vos démarches administratives, pour votre travail, pour l’école…).
      Comment on travaille ?

      En individuel            A deux ou en groupes      En grand groupe  

      Renseignements pratiques
      Quand ? (Les cours hebdomadaires ou bi-hebdomadaires ont en général une durée de 2 h).
      ⦁	En journée  (matin ou après-midi)
      ⦁	En soirée (18 h 30 – 20 h 30)
      D’octobre à juin (hors vacances scolaires)
      Où ?
      ⦁	A la Maison Pour Tous J. BAKER
      ⦁	A la Maison Pour Tous Y. GAGARINE
      ⦁	Au 153, rue Diderot


      Contact :
      Renseignements et demandes d’inscription :
      06 59 00 17 27
      champignydicietdailleurs@gmail.com
      Vous pouvez également prendre contact via le formulaire ci-dessous pour nous poser des questions ou faire votre demande d’inscription à nos ateliers. N’oubliez pas de préciser votre nom et prénom dans le message !
      </p>

    </div>
  );
}

export default App;
